<template>
  <modal
    data-testid="about-us-modal"
    class="text-center text-paragraph-gray"
    @close="$emit('close')"
  >
    <div class="text-center p-2 -mt-8">
      <img
        src="/creators.gif"
        class="creators mx-auto px-4"
      >
      <div>
        <p>
          We are naive tech builders who enjoy chill and not-too-noisy cocktail bars.
          The atmosphere of people quietly chatting, bartenders actively preparing drinks, and smooth jazz music in the background will never ever bore us.
          And we believe that we can experience some of that over the internet with strangers as well.
        </p>
        <p>Hope you Enjoy ! The drinks are on us :)</p>
      </div>
      <div class="pt-5">
        <p>
          Developed & Designed by <a
            class="text-black cursor-pointer"
            target="_blank"
            href="https://www.linkedin.com/in/wasin-watthanasrisong-23615815a/"
          >WasinWatt</a> - <a
            class="text-black cursor-pointer"
            target="_blank"
            href="https://www.linkedin.com/in/chalipa-dulyakorn-b0836a12b/"
          >NamoTotae</a>
        </p>
        <p>
          Inspired by <a
            class="text-black cursor-pointer"
            target="_blank"
            href="https://imissmycafe.com"
          >imissmycafe.com</a>
        </p>
      </div>
      <!-- <div class="pt-5">
        <p>Special thanks</p>
        <p>Background music by <a class="text-black cursor-pointer" target="_blank" href="https://www.youtube.com/c/LuKremBo">LuKremBo</a></p>
        <p>Ambient sound by <a class="text-black cursor-pointer" target="_blank" href="https://freesound.org/people/priesjensen/sounds/482990/">Priesjensen</a> + <a class="text-black" target="_blank" href="https://freesound.org/people/kessir/sounds/264759/">Kessir</a></p>
      </div> -->
      <div class="pt-5">
        <p>Join us on</p>
        <div class="flex justify-center py-1">
          <a
            class="cursor-pointer w-9 px-1"
            target="_blank"
            href="https://discord.gg/EJeRUY4WBn"
          ><img src="~/public/assets/images/social/discord.png"></a>
          <a
            class="cursor-pointer w-9 px-1"
            target="_blank"
            href="https://twitter.com/Drinksonme_Bar"
          ><img src="~/public/assets/images/social/twitter.png"></a>
          <a
            class="cursor-pointer w-9 px-1"
            target="_blank"
            href="https://www.tiktok.com/@namototae?_t=8VBeII2bFMv&_r=1"
          ><img src="~/public/assets/images/social/tiktok.png"></a>
        </div>
      </div>
      <p class="mt-2 text-lg text-black">In case you want to contact us ⬇</p>
      <div class="p-1 sm-px-16">
        <p>
          Name: BETABUILDER CO., LTD.
        </p>
        <p>
          Address: Thaksin Mueng Thong 5, Hat Yai, Hat Yai, Songkhla 90110
        </p>
        <p>
          Tel: 085-977-0646
        </p>
        <p>
          Email: drinksonme.live@gmail.com
        </p>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '~/components/common/Modal.vue'

export default {
  components: {
    Modal,
  },
}
</script>

<style lang="scss" scoped>
.creators {
  max-width: 349px;
  max-height: 210px;
}
</style>
